/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Michroma&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

:root {
  --font-Michroma: 'Michroma', sans-serif;
  --font-Poppins: 'Poppins', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100% !important;
  height: 100% !important;
}

#root {
  width: 100% !important;
  height: 100% !important;
}

body {
  font-family: var(--font-Poppins) !important;
  margin: 0 !important; /* Eliminar margen predeterminado del cuerpo */
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #464646 !important;
  /* background-image: url('Cybertruck.png');
  background-repeat: no-repeat;
  background-size: cover; */
}

/*Contendor principal*/
.contenedor-flex{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.button-contenedor {
  transition: all 0.3s ease;
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centra los elementos horizontalmente */
  align-items: center; /* Centra los elementos verticalmente */
  margin-right: auto;
  margin-left: auto;
  
}

.responsive-edit{
  width:833px ;
  height:365px;
  background-color: #313130;
  border-radius: 20px;
  margin-left: 25vh;
  margin-top: 1%;
  box-shadow: 0px 10px 40px rgb(31, 30, 30);
  z-index: 2;

  
  }
  .menu-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    
  }
  
  .G-div{
    
    width: 60%;
    height: 100%;
    margin-right: auto;
    z-index: 2;
  }
 

  @media (max-width: 530px) {
    
  
    .responsive-edit{
      transition: all 0.3s ease;
      width:403px ;
      height:250px;
      background-color: #313130;
      border-radius: 20px;
      margin-top: 5%;
      margin-right: auto;
      margin-left: auto;
      z-index: 1;
      }
      
      .button-contenedor {
        transition: all 0.3s ease;
        width: 100%;
        
        border-radius: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center; /* Centra los elementos horizontalmente */
        align-items: center; /* Centra los elementos verticalmente */
        margin-right: auto;
        margin-left: auto;
        
    }

    .button-contenedor .child {
        margin: 1px; /* Espacio entre los divs */
        padding: 2px; /* Espacio interno de los divs */
    }
  }

  /* Estilos para la barra de navegación */
.navbar {
  padding: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* height: 130px; */
 
}

/* Estilos para la sección de menú desplegable */
.secciondos {
  z-index: 2;
  text-align: center;
  width: calc(100%/3);
  height: 60px;
 
  background-color: rgba(255, 255, 255, 0.253);
  border-bottom-left-radius: 20px; /* Radio de borde inferior izquierdo */
  border-bottom-right-radius: 20px; /* Radio de borde inferior derecho */
  overflow: hidden; /* Para ocultar el contenido que está fuera del contenedor */
  transition: height 0.4s ; /* Agrega una transición suave para la altura */
  cursor: pointer;
}

/* Estilos para la expansión de la sección de menú desplegable */
.secciondos.expanded {
  height: 80px; /* Altura mayor para la expansión */
}

/* Estilos para las imágenes dentro de la sección de menú desplegable */
.secciondos div img {
  opacity: 0; /* Hace que las imágenes estén inicialmente ocultas */
  transition: opacity 0.5s ease; /* Agrega una transición suave para la opacidad */
}
/* Estilos para las imágenes en la barra de navegación */
.navbar img {
  transition: transform 0.3s ease; /* Transición suave al hacer hover */
}

/* Estilos para las imágenes cuando se les hace hover */
.navbar img:hover {
  transform: scale(1.1); /* Escala ligeramente la imagen al hacer hover */
}

/* Estilos para mostrar las imágenes dentro de la sección de menú desplegable */
.secciondos.expanded div img {
  opacity: 1; /* Hace que las imágenes se vuelvan visibles cuando se expande */
}

/* Estilos para las demás secciones */
.seccion {
  margin-left: 1rem;
 
}
.seccion3{
  position: relative;
  height: auto;
}



#menu1:hover{color: #ffffff;
  transform: scale(1.2);
}
.menu-icons {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: 25px; /* Ajusta el margen derecho según sea necesario */
  transition: transform 0.3s ease;
  float: right; /* Coloca el elemento a la derecha */
}


#menu1 {
  color: #555;
  cursor: pointer;
  margin-right: 20px; /* Ajusta el margen derecho según sea necesario */
  transition: transform 10.0s ease;
  float: right; /* Coloca el elemento a la derecha */
}

.menuicons {
  color: #555;
  cursor: pointer;
  margin-right: 10px;
}
#AC:hover{
  color: #ffffff;
  transform: scale(1.0);
}
#AC{
  color: #555;
  cursor: pointer;
}
.menuicons:hover {
  transform: scale(1.0);
}

.menuicons:hover {
  color: #ffffff;
}




/*menu de partes
.menu-seleccion{
  width: 300px;
  height: 300px;
  border-radius: 20px;
  background-color: rgba(139, 136, 136, 0.315);
  margin-top: 7%;
  margin-right: 2%;

}

/*estilo de menu de la izquierda*/
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  margin-top: 5%;
  padding: 0;
  width:2%; /* Tamaño inicial */
  height: 620px;
  background-color: #4b4b4b33;
  border-radius: 10px;
  transition: width 0.5s ease, height 0.5s ease; /* Agregar transición de ancho y altura */
}

.App.expanded {
  transition: width 0.5s ease, height 0.5s ease; /* Agregar transición de ancho y altura */
  width: 400px; /* Ancho expandido */
  height: 620px; /* Altura expandida */
}
.App.expanded .slide {
 
  opacity: 10; /* Hacer que el contenido sea visible */
  transition: opacity 0s ease; /* Agregar una transición suave para la opacidad */
}
.slide img {
  width: 10rem;
  margin: 0 auto;
  background-color: rgba(128, 128, 128, 0.418);
  border-radius: 200px;
}

.slide {
  transform: scale(.6);
  transition: transform 300ms;
  opacity: 0.3;
}

.activeSlide {
  transform: scale(1.0);
  opacity: 1;
}

.arrow {
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
   color: rgba(231, 220, 220, 0.425);
  transition: color 300ms;
}

.arrow svg:hover {
  color: #ffffff;
}



.next {
  right: 48%; /* Colocar la flecha derecha al lado derecho del carrusel */
  top: 99%; /* Colocar la flecha derecha en el centro vertical */
  transform: translateY(-50%); /* Alinear la flecha derecha al centro vertical */
}

.prev {
  left: 43%; /* Colocar la flecha izquierda al lado izquierdo del carrusel */
  top: 3%; /* Colocar la flecha izquierda en el centro vertical */
  transform: translateY(-50%); /* Alinear la flecha izquierda al centro vertical */
}
.expand-arrow:hover { 
  color: #555555;}

.expand-arrow{
  color: antiquewhite;
  top: 50%;
  position: absolute;
  cursor: pointer;
  z-index: 15;
}

/*Menu de partes*/

.contenedor {
  z-index: 1;
  width: 300px;
  height: 300px;
  border-radius: 20px;
  overflow-y: scroll;
  background-color: rgba(139, 136, 136, 0.315);
  margin-top: 10%;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  transition: opacity 10.6 ease; /* Agregar una animación de transición */
}

.cuadrado {
  width: 100px;
  height: 100px;
  background-image: url(../img/cybertruck/carroceria.jpg);
  background-size: cover;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center; /* Centrar contenido vertical y horizontalmente */
  margin: 20px;

}

.contenedor::-webkit-scrollbar {
  width: 12px;
}

.contenedor::-webkit-scrollbar-track {
  background: transparent;
}

.contenedor::-webkit-scrollbar-thumb {
  background: #888;
  border-radius:10px;
}

.contenedor::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.iconos-areamenu{
  width:100%;
  }

#faTimes:hover{color: #ffffff;}

#faTimes{
  width:50%;
  height:2%;
  color: #232424;
}
.menu-edit {
  z-index: 1;
  width: 290px;
  height: 290px;
  border-radius: 20px;
  background-color: rgba(139, 136, 136, 0.315);
  margin-top: 7%;
  margin-right: 2%;
  display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
}

input[type="color"] {
  width: 200px;
  height: 129px;
  border: 1px solid #ccc;
  border-radius: 20ppx;
  margin: auto; /* Centrar el input dentro del div */
}


@media (min-width: 700px) {
  .Apph{display: none;}
}
@media (max-width: 430px) {
  .App {
    display: none;
  }
  
  .contenedor-flex {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column; /* Apilar los elementos verticalmente */
    position: relative;
    overflow: auto; /* Manejar el desbordamiento */
  }
  
  .contenedor-wrapper {
    display: flex;
    justify-content: space-between; /* Distribuir los elementos alrededor */
    margin-right: 20px; /* Espacio entre .contenedor y .menu-edit */
  }
  
  .contenedor {
    z-index: 1;
    width: 160px;
    height: 170px;
    border-radius: 20px;
    overflow-y: scroll;
    background-color: rgba(139, 136, 136, 0.315);
    margin-top: 10%;
    display: flex;
    flex-wrap: wrap;
    transition: opacity 10.6s ease;
    position: relative;
    flex-shrink: 0; /* Evitar que se comprima */
  }
  
  .cuadrado {
    width: 100px;
    height: 100px;
    background-image: url(../img/cybertruck/carroceria.jpg);
    background-size: cover;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
  
  .menu-edit {
    margin-top: 240px;
    width: 80px;
    height: 80px;
    border-radius: 20px;
    background-color: rgba(139, 136, 136, 0.315);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  input[type="color"] {
    width: 80px;
    height: 80px;
    border: 1px solid #2c2a2a;
    border-radius: 5px;
    margin: auto;
  }
  
  .Apph {
    z-index: 1;
    margin-top: 20px; /* Añadir margen para separar de los elementos superiores */
    width: 400px;
    height: auto;
    background-color: #4b4b4b33;
    border-radius: 10px;
    transition: width 0.5s ease, height 0.5s ease;
    position: relative;
    flex-shrink: 0;
  }
}